import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/', data: {showSideBar: true}},
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    data: {showSideBar: false}
  },

  {
    path: 'news',
    loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule),
    data: {showSideBar: true}
  },
  {
    path: 'appeal',
    loadChildren: () => import('./modules/appeal/appeal.module').then(m => m.AppealModule),
    data: {showSideBar: true}
  },
  {
    path: 'loans',
    loadChildren: () => import('./modules/loans/loans.module').then(m => m.LoansModule),
    data: {showSideBar: true}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
