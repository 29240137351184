<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar" *ngIf="showSiderBar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <a  target="_blank">
        <!--        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">-->
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Xəbər" nzIcon="profile">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/news/list">Xəbər siyahısı</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/news/create">Xəbər əlavə et</a>
          </li>
          <!--          <li nz-menu-item nzMatchRouter>-->
          <!--            <a>Workplace</a>-->
          <!--          </li>-->
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="Müraciət" nzIcon="wallet">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/appeal/list">Müraciətlər</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="Kredit" nzIcon="wallet">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/loans/list">Kredit növləri</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/loans/create">Kredit növü yarat</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header" *ngIf="showSiderBar">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <span class="trigger"
                  nz-icon
                  [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></span>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
